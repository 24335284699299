<template>
  <div class="container">
    <router-view/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
});
</script>

<style>
:root {
  --light-green-pecsmart: #78b843;
  --dark-green-pecsmart: #6ca63c;
  --btn-green: #a1cd7b;
  --btn-hover-green: #1b4238;
  --dark-white: #f4f4f4;
  --light-grey: #d8d8d8;
  --dark-grey: #6e6e6e;
  --light-black: #404040;
  --warning-light-yellow: #ffc870;
  --warning-yellow: #ffbe56;
  --warning-dark-yellow: #ffac2f;
  --warning-darker-yellow: #d68b1b;
  --error-lighter-red: #ff7471;
  --error-light-red: #ff4f4d;
  --error-dark-red: #e91916;
  --blue: #219ebc;
  --purple: #be95c4;
}

body {
  margin: 0;
  font-family: "Helvetica";
}

.container {
  display: flex;
  flex-direction: row;

  height: 100vh;
}

.form form {
  width: clamp(320px, 50%, 540px);
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.form form label {
  width: 100%;
}

.form form input, select{
  padding: 6px 24px;
  height: 48px;
  width: 100%;
  margin: 12px 0;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 6px;
  border: .5px solid var(--light-grey);
}

.form form input:-webkit-autofill {
  -webkit-box-shadow:200px 200px 100px white inset; 
  box-shadow:200px 200px 100px white inset;
}

.register-button {
  background: var(--light-green-pecsmart);
  color: #fff;
  width: 256px;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px 12px 20px;
  border-radius: 12px;
  cursor: pointer;
}

.register-button:hover {
  background: var(--dark-green-pecsmart);
}

.register-button-disabled {
  background: var(--dark-grey);
  color: #fff;
  width: 256px;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px 12px 20px;
  border-radius: 12px;
  margin: 24px 0;
  cursor: pointer;
}

.register-button-disabled:hover {
  cursor: not-allowed;
}

#options-modal-button {
  color: white;
  border: 4px solid transparent;
  border-radius: 6px;
  transition: 0.2s;
  margin: 6px 12px;
  cursor: pointer;
}

#options-modal-button h1 {
  margin: 6px;
  font-size: 18px;
}

.bg-green {
  background: var(--light-green-pecsmart);
}

.bg-green:hover {
  background: var(--dark-green-pecsmart);
}

.bg-yellow {
  background: var(--warning-dark-yellow);
}

.bg-yellow:hover {
  background: var(--warning-darker-yellow);
}

.bg-red {
  background: var(--error-light-red);
}

.bg-red:hover {
  background: var(--error-dark-red);
}
</style>
